import React, { useState, useEffect} from "react";
import { Link} from 'react-router-dom';
import EventFormPopup from "../../components/EventPopup/EventPopup";
import axios from "axios";
import "./AdminControls.css";
import ScrollButton from "../../components/ScrollTopButton/ScrollButton";

const AdminControls = () => {
  const [data, setData] = useState();
  const backendUrl = "https://divadelier-backend.vercel.app"
  const token = localStorage.getItem("token");

  useEffect(() => {
    async function grabData() {
      const response = await axios.get(backendUrl+"/events", {
        headers: {
          'authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setData(response.data);
      }
    }
    grabData();
  }, [token]);

  const [showPopup, setShowPopup] = useState(false);

  const addEvent = async (event) => {
    const response = await axios.post(backendUrl+"/events", event, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    });
    setData([...data, response.data]);
    window.location.reload();
  };

  const deleteEvent = async (id) => {
    await axios.delete(backendUrl + `/events/${id}`, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    });
    setData(data.filter(event => event._id !== id));
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}. ${month} ${year}`;
  };

  if(!token){
    alert("Nejsi přihlášen!!!")
  } else{
    return (
      <div className='admin-control'>
        <h1>Správa představení</h1>
        <button className='add-event-button' onClick={togglePopup}>
          Přidat představení
        </button>
        <Link to={"/"}><button className="add-event-button">Zpět na web</button></Link>
        <div className='event-list'>
          {data&&data.map((event, index) => (
            <div key={index} className='controls-event-item'>
              <h3>Datum: {formatDate(event.date)}</h3>
              <p>Kde se hraje: {event.location}</p>
              <p>Co se hraje: {event.info}</p>
              <button onClick={() => deleteEvent(event._id)}>Delete</button>
            </div>
          ))}
        </div>
        {showPopup && (
          <EventFormPopup addEvent={addEvent} closePopup={togglePopup} />
        )}
        <ScrollButton/>
      </div>
    );
  }
};

export default AdminControls;
