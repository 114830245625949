import React, { useState } from "react";
import "./EventPopup.css";

const EventPopup = ({ addEvent, closePopup }) => {
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [info, setInfo] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    addEvent({ date, location, info });
    closePopup();
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>Přidat nové představení</h2>
        <form className="add-form" onSubmit={handleSubmit}>
          <label>
            Datum:
            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
          </label>
          <label>
            Kde se hraje:
            <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
          </label>
          <label>
            Co se hraje:
            <input type="text" value={info} onChange={(e) => setInfo(e.target.value)} required />
          </label>
          <div className="form-buttons">
            <button type="submit">Přidat</button>
            <button type="button" onClick={closePopup}>Zrušit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventPopup;
