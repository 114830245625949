import React, { useState } from 'react';
import axios from 'axios';
import './AdminLogin.css';
import {useNavigate} from "react-router-dom";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const backendUrl = "https://divadelier-backend.vercel.app"

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(backendUrl+'/login', { username, password });
            if (response.data.success) {
                alert('Přihlášení úspěšné');
                localStorage.setItem('token', response.data.token);
                navigate("/admincontrols");
            } else {
                setError('Špatné přihlašovací jméno nebo heslo');
            }
        } catch (error) {
            console.log(error)
            setError('Vyskytl se problém, zkuste to znovu');
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleLogin}>
                <h2>Přihlášení administrátora</h2>
                {error && <p className="error">{error}</p>}
                <div className="form-group">
                    <h3 htmlFor="username">Jméno:</h3>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <h3 htmlFor="password">Heslo:</h3>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button className="login-button" type="submit">Přihlásit se</button>
            </form>
        </div>
    );
};

export default Login;
